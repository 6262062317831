import { useCallback } from "react";

import { Container } from "@material-ui/core";
import moment from "moment";

import { ContentSection } from "../../application/ContentSection";
import { VerticalButtons, GreenButton } from "../../components/new-button";
import { Section } from "../../components/section/Section";
import { useTranslation } from "../../internationalisation/translation.hook";
import { CardStatus, CardStatusProps } from "../../components/card";
import { formatTaskDate } from "../shared/utility";
import { AppointmentFollowUpTaskDocument, RegistrationFollowUpTaskDocument, TaskDocument } from "../../task/task.model";
import { SectionHeader } from "../../components/section/SectionHeader";
import { determineDaysUntilTaskDeadline } from "../../utility";
import { TaskResponseData } from "../../task/task-response-action.hook";
import { FormController } from "../../components/form-item/framework/core/form.controller";
import { FormProps } from "../../components/form-item/framework/react/FormProvider";
import { DatePickerFormItem, SingleChoiceFormItem, TextAreaFormItem } from "../../components/form-item/client/components";
import { FormItem } from "../../components/form-item/framework/core/form-item.model";
import { AppointmentTaskResponseDialogIds, useAppointmentTaskResponseDialog, RegisterContactAttemptBranchIds, PostponeContactBranchIds, determinePostponeDates } from "../../components/form-item/client/application/appointment-task-dialog";
import { RejectCustomerBranchIds } from "../../components/form-item/client/application/reject-customer-dialog";
import { FloatingButton } from "../../components/floating-button/FloatingButton";
import { GardeningTaskGardenerViewDocument } from "../../gardening-task/gardener-view/gardening-task-gardener-view.model";
import { GardeningTaskCard } from "../../gardening-task/gardener-view/GardeningTaskCard";
import { AppointmentTaskGuideList } from "../../guides/AppointmentTaskGuideList";
import { GardenerDocument } from "../../gardener/gardener.model";

interface AppointmentTaskDetailPageProps {
    gardener: GardenerDocument;
    task: AppointmentFollowUpTaskDocument
    gardeningTask: GardeningTaskGardenerViewDocument;
    hasHistory: boolean;
    hasRecentHistory: boolean;
    now: string;
    taskResponseAction: (data: TaskResponseData | string) => void;
}

export const AppointmentTaskDetailPage = (props: AppointmentTaskDetailPageProps) => {
    const { gardener, task, gardeningTask, hasHistory, hasRecentHistory, now, taskResponseAction } = props;

    const { t } = useTranslation();

    const isFuture = moment(task.performAfterDate).isAfter(now, "day");
    const appointmentTaskResponseActionAdapter = useCallback<FormProps<FormItem>["onSubmit"]>((controller, form): void => {
        const data = mapAppointmentTaskResponseFormToAppointmentTaskResponseData(controller, form, task, gardeningTask, now, isFuture);

        taskResponseAction(data);
    }, [taskResponseAction, task, gardeningTask, now, isFuture]);

    const openAppointmentTaskResponseDialog = useAppointmentTaskResponseDialog(hasHistory, hasRecentHistory, isFuture, now, gardeningTask, appointmentTaskResponseActionAdapter);

    return (
        <ContentSection>

            <Container maxWidth="sm" disableGutters>

                <Section>

                    <GardeningTaskCard
                        status={(
                            <>
                                <CardStatus color={determineStatusColor(task, now)} status={formatTaskDate(task, now, t)} />
                            </>
                        )}
                        title={t("CurrentTasksTab: appointment-task-title")}
                        gardeningTask={gardeningTask}
                    />

                    <FloatingButton>
                        <VerticalButtons>
                            <GreenButton onClick={openAppointmentTaskResponseDialog}>{t("CurrentTasksTab: task-response-action")}</GreenButton>
                        </VerticalButtons>
                    </FloatingButton>

                </Section>

            </Container>

            <Container maxWidth="md" disableGutters>
                <Section>
                    <SectionHeader>Relevante guides</SectionHeader>
                    <AppointmentTaskGuideList gardener={gardener} taskResponseAction={taskResponseAction} />
                </Section>
            </Container>

        </ContentSection>
    );
};

function determineStatusColor(task: TaskDocument, now: string): CardStatusProps["color"] {
    const days = determineDaysUntilTaskDeadline(task, now);

    if ( days > 0 ) return "default";
    if ( days === 0 ) return "warning";

    return "danger";
}

export const mapRejectCustomerBranchToTaskResponseData = (controller: FormController, form: FormItem, task: AppointmentFollowUpTaskDocument | RegistrationFollowUpTaskDocument, ids: Pick<RejectCustomerBranchIds, "singleChoiceDoesCustomerStillWantHelp">): TaskResponseData => {
    const doesCustomerStillWantHelp = (() => {
        const wantsHelp = (controller.getItem(ids.singleChoiceDoesCustomerStillWantHelp, form) as SingleChoiceFormItem<FormItem[]>);
        if ( wantsHelp.selectedChoiceIndex === 0 ) return "no";
        if ( wantsHelp.selectedChoiceIndex === 1 ) return "yes";
        if ( wantsHelp.selectedChoiceIndex === 2 ) return "don't know";
    })();

    if ( doesCustomerStillWantHelp === "yes" ) {
        return {
            action: "reject task because gardener could not help customer",
            taskId: task.id,
            reason: "Havemanden ønsker ikke at hjælpe kunden.",
        };
    }

    if ( doesCustomerStillWantHelp === "don't know" ) {
        return {
            action: "reject task because gardener says customer might need help",
            taskId: task.id,
            reason: "Havemanden ved ikke, om kunden ønsker hjælp.",
        };
    }

    if ( doesCustomerStillWantHelp === "no" ) {
        return {
            action: "reject task because gardener says customer does not need help",
            taskId: task.id,
            reason: "Kunden ønsker ikke hjælp.",
        };
    }

    throw new Error("Unexpected scenario");
}

export const mapRegisterContactAttemptBranchToTaskResponseData = (controller: FormController, form: FormItem, task: AppointmentFollowUpTaskDocument, numberOfPostponements: number, ids: RegisterContactAttemptBranchIds): TaskResponseData | string => {
    const threeCallAttempts = !(numberOfPostponements < 3);
    if (  threeCallAttempts ) {
        return {
            action: "register contact attempt",
            taskId: task.id,
            shouldGiveUp: true,
        };
    }

    const wantsToCallAgain = (controller.getItem(ids.singleChoiceWantsToCallAgain, form) as SingleChoiceFormItem<FormItem[]>).selectedChoiceIndex === 1;

    return {
        action: "register contact attempt",
        taskId: task.id,
        shouldGiveUp: !wantsToCallAgain,
    };
}

export const mapPostponeContactBranchToTaskResponseData = (controller: FormController, form: FormItem, task: AppointmentFollowUpTaskDocument, gardeningTask: GardeningTaskGardenerViewDocument, now: string, ids: PostponeContactBranchIds): TaskResponseData => {
    const { postponeDateMinimum, postponeDateMaximum } = determinePostponeDates(gardeningTask, now);
    const timeHasAlreadyPassed = postponeDateMinimum > postponeDateMaximum;

    if ( timeHasAlreadyPassed ) {
        return {
            action: "reject task because gardener could not find date with customer",
            taskId: task.id,
            reason: "Havemanden lavede ikke en aftale med kunden inden for rimelig tid.",
        };
    }

    const expectToFindDate = (() => {
        const expectsToContact = controller.getItem(ids.singleChoiceDoYouExpectToFindDate, form) as SingleChoiceFormItem<FormItem[]>;
        if ( expectsToContact.selectedChoiceIndex === 0 ) return "no";
        if ( expectsToContact.selectedChoiceIndex === 1 ) return "yes";
        if ( expectsToContact.selectedChoiceIndex === 2 ) return "don't know";
    })();

    if ( expectToFindDate === "no" || expectToFindDate === "don't know" ) {
        return {
            action: "reject task because gardener could not find date with customer",
            taskId: task.id,
            reason: "Havemanden forventer ikke at tale med kunden inden for rimelig tid.",
        };
    }

    if ( expectToFindDate === "yes" ) {
        const date = (controller.getItem(ids.datePickerWhenDoYouExpectToFindDate, form) as DatePickerFormItem).value;

        return {
            action: "postpone contact",
            taskId: task.id,
            reason: "Havemanden forventer at tale med kunden på datoen.",
            date,
        };
    }

    throw new Error("Unexpected scenario");
}

const mapAppointmentTaskResponseFormToAppointmentTaskResponseData = (controller: FormController, form: FormItem, task: AppointmentFollowUpTaskDocument, gardeningTask: GardeningTaskGardenerViewDocument, now: string, isFuture: boolean): TaskResponseData | string => {
    const contactAttempts = gardeningTask.working.contactAttempts + 1;

    const selectedActionIndex = (controller.getItem(AppointmentTaskResponseDialogIds.SingleChoiceAction, form) as SingleChoiceFormItem<FormItem[]>).selectedChoiceIndex;
    if ( selectedActionIndex === null ) return "expected selected action";

    const actions = !isFuture ? ["create appointment" as const, "contact attempt" as const, "postpone task" as const, "close task" as const] : ["create appointment" as const, "postpone task" as const, "close task" as const];
    const selectedAction = actions[selectedActionIndex];

    if ( selectedAction === "create appointment" ) {
        const date = (controller.getItem(AppointmentTaskResponseDialogIds.GardenerCreatedAppointmentDate, form) as DatePickerFormItem).value;
        const description = (controller.getItem(AppointmentTaskResponseDialogIds.GardenerCreatedAppointmentDescription, form) as TextAreaFormItem).value;
    
        return {
            action: "create appointment",
            taskId: task.id,
            date,
            description,
        };
    }

    if ( selectedAction === "contact attempt" ) {
        return mapRegisterContactAttemptBranchToTaskResponseData(controller, form, task, contactAttempts, {
            singleChoiceWantsToCallAgain: AppointmentTaskResponseDialogIds.HaveCalledAndNotReachedCustomerAndStillWantsToHelpCustomerAndGardenerDoesNotHaveThreeAttemptsButDoesGardenerWantToContactAgain,
        });
    }

    if ( selectedAction === "postpone task" ) {
        return mapPostponeContactBranchToTaskResponseData(controller, form, task, gardeningTask, now, {
            singleChoiceDoYouExpectToFindDate: AppointmentTaskResponseDialogIds.HaveCalledAndReachedCustomerAndWantsToHelpCustomerAndCustomerWantsHelpButExpectsToContact,
            datePickerWhenDoYouExpectToFindDate: AppointmentTaskResponseDialogIds.HaveCalledAndReachedCustomerAndWantsToHelpCustomerAndCustomerWantsHelpAndExpectsToContactDate,
        });
    }

    if ( selectedAction === "close task" ) {
        return mapRejectCustomerBranchToTaskResponseData(controller, form, task, {
            singleChoiceDoesCustomerStillWantHelp: AppointmentTaskResponseDialogIds.SingleChoiceDoesCustomerStillWantHelp,
        });
    }

    return "unknown action";
};
